import { Backdrop, CircularProgress } from "@mui/material";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useQuery } from "../../hooks/useQuery";
import { AuthActions } from "../../store/auth/AuthActions";
import {
    getAuthenticatedDataRoomUser,
    getDataRoomLoginFailed,
    getDataRoomLoginInProcess,
} from "../../store/auth/AuthSelectors";
import { appUrl, authUrl } from "../../utils/url";
import { useLocation, useNavigate } from "react-router";

export const Login = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const query = useQuery();
    const dispatch = useDispatch();

    const status = query.get('status');
    const signedRedirectUrl = query.get('signedRedirectUrl');
    const path = query.get('path');

    const user = useSelector(getAuthenticatedDataRoomUser);
    const dataRoomLoginInProcess = useSelector(getDataRoomLoginInProcess);
    const dataRoomLoginFailed = useSelector(getDataRoomLoginFailed);

    const redirectToLogin = useCallback(() => {
        window.location.replace(authUrl(
            '/login/dataroom',
            {
                offlineAccess: 'true',
                'redirect_url': appUrl(location.pathname, path ? { path } : {}),
            },
        ));
    }, [location.pathname, path]);

    useEffect(() => {
        if (status === 'Authenticated' && signedRedirectUrl) {
            dispatch(AuthActions.dataRoomLogin(signedRedirectUrl));
        } else if (user === null) {
            redirectToLogin();
        }
    }, [dispatch, redirectToLogin, signedRedirectUrl, status, user]);

    useEffect(() => {
        if (user !== null) {
            void navigate(path || '/statistics');
        }
    }, [navigate, user, path]);

    useEffect(() => {
        if (dataRoomLoginFailed && !dataRoomLoginInProcess) {
            redirectToLogin();
        }
    }, [redirectToLogin, dataRoomLoginInProcess, dataRoomLoginFailed]);

    return <Backdrop open>
        <CircularProgress />
    </Backdrop>;
};
