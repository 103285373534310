import { Backdrop, CircularProgress } from "@mui/material";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Location, useLocation, useNavigate } from "react-router";
import { useQuery } from "../../hooks/useQuery";
import { AuthActions } from "../../store/auth/AuthActions";
import {
    getAuthenticatedBorrower,
    getBorrowerLoginFailed,
    getBorrowerLoginInProcess,
} from "../../store/auth/AuthSelectors";
import { appUrl, authUrl } from "../../utils/url";

export const Login = () => {
    const navigate = useNavigate();
    const location: Location<{ logoutRedirectUrl?: string }> = useLocation();
    const query = useQuery();
    const dispatch = useDispatch();

    const status = query.get('status');
    const signedRedirectUrl = query.get('signedRedirectUrl');
    const path = query.get('path');
    const logoutRedirectUrl = query.get('logoutRedirectUrl');
    const stateLogoutRedirectUrl = location.state?.logoutRedirectUrl;

    const borrower = useSelector(getAuthenticatedBorrower);
    const borrowerLoginInProcess = useSelector(getBorrowerLoginInProcess);
    const borrowerLoginFailed = useSelector(getBorrowerLoginFailed);

    const redirectToLogin = useCallback(() => {
        window.location.replace(authUrl(
            '/login/borrower',
            {
                offlineAccess: 'true',
                'redirect_url': appUrl(location.pathname, path ? { path } : {}),
            },
        ));
    }, [location.pathname, path]);

    useEffect(() => {
        if (stateLogoutRedirectUrl) {
            window.location.replace(stateLogoutRedirectUrl);
            return;
        }

        if (status === 'Authenticated' && signedRedirectUrl) {
            dispatch(AuthActions.borrowerLogin(signedRedirectUrl, logoutRedirectUrl));
        } else if (borrower === null) {
            redirectToLogin();
        }
    }, [
        dispatch,
        redirectToLogin,
        logoutRedirectUrl,
        stateLogoutRedirectUrl,
        status,
        signedRedirectUrl,
        borrower,
    ]);

    useEffect(() => {
        if (borrower !== null) {
            void navigate(path || '/dashboard');
        }
    }, [navigate, borrower, path]);

    useEffect(() => {
        if (borrowerLoginFailed && !borrowerLoginInProcess) {
            redirectToLogin();
        }
    }, [redirectToLogin, borrowerLoginInProcess, borrowerLoginFailed]);

    return <Backdrop open>
        <CircularProgress />
    </Backdrop>;
};
