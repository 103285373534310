import { all, call, put, takeLatest } from 'redux-saga/effects';
import { StorageKey } from '../../constants/StorageKey';
import { authService } from '../../services/authService';
import { storage } from '../../utils/storage';
import {
    AuthActions,
    AUTHENTICATE_BORROWER,
    AUTHENTICATE_DATA_ROOM_USER,
    BORROWER_LOGIN,
    BORROWER_LOGOUT,
    BORROWER_LOGOUT_SUCCESS,
    DATA_ROOM_LOGIN,
    DATA_ROOM_LOGOUT,
    DATA_ROOM_LOGOUT_SUCCESS,
} from './AuthActions';

function* borrowerLogin(action: ReturnType<typeof AuthActions.borrowerLogin>) {
    try {
        const { signedRedirectUrl, logoutRedirectUrl } = action.payload;

        const {
            token,
            refreshToken,
        }: Awaited<ReturnType<typeof authService.fetchTokens>> = yield call(authService.fetchTokens, signedRedirectUrl);
        storage.put(StorageKey.BORROWER_TOKEN, token);
        storage.put(StorageKey.BORROWER_REFRESH_TOKEN, refreshToken);
        if (logoutRedirectUrl) {
            storage.put(StorageKey.BORROWER_LOGOUT_REDIRECT_URL, logoutRedirectUrl);
        }

        const borrower: Awaited<ReturnType<typeof authService.authenticatedBorrower>> = yield call(authService.authenticatedBorrower);

        yield put(AuthActions.borrowerLoginSuccess(borrower));
    } catch (e) {
        yield put(AuthActions.borrowerLoginFailed());
    }
}

function* borrowerLogout(action: ReturnType<typeof AuthActions.borrowerLogout>) {
    try {
        const { navigate } = action.payload;
        storage.delete(StorageKey.BORROWER_TOKEN);
        yield put(AuthActions.borrowerLogoutSuccess(navigate));
    } catch (e) {
        yield put(AuthActions.borrowerLogoutFailed());
    }
}

function borrowerLogoutSuccess(action: ReturnType<typeof AuthActions.borrowerLogoutSuccess>) {
    const { navigate } = action.payload;
    const logoutRedirectUrl = storage.get(StorageKey.BORROWER_LOGOUT_REDIRECT_URL);

    void navigate('/login', { state: { logoutRedirectUrl } });
}

function* authenticateBorrower() {
    try {
        const token = storage.get(StorageKey.BORROWER_TOKEN);

        if (token) {
            const borrower: Awaited<ReturnType<typeof authService.authenticatedBorrower>> = yield call(authService.authenticatedBorrower);
            yield put(AuthActions.authenticateBorrowerSuccess(borrower));
        } else {
            yield put(AuthActions.authenticateBorrowerFailed());
        }
    } catch (e) {
        storage.delete(StorageKey.BORROWER_TOKEN);
        yield put(AuthActions.authenticateBorrowerFailed());
    }
}

function* dataRoomUserLogin(action: ReturnType<typeof AuthActions.dataRoomLogin>) {
    const { signedRedirectUrl } = action.payload;
    try {
        const {
            token,
            refreshToken,
        }: Awaited<ReturnType<typeof authService.fetchTokens>> = yield call(authService.fetchTokens, signedRedirectUrl);

        storage.put(StorageKey.DATA_ROOM_TOKEN, token);
        storage.put(StorageKey.DATA_ROOM_REFRESH_TOKEN, refreshToken);

        const user: Awaited<ReturnType<typeof authService.authenticatedDataRoomUser>> = yield call(authService.authenticatedDataRoomUser);

        yield put(AuthActions.dataRoomLoginSuccess(user));
    } catch (e) {
        yield put(AuthActions.dataRoomLoginFailed());
    }
}

function* dataRoomUserLogout(action: ReturnType<typeof AuthActions.dataRoomLogout>) {
    try {
        const { navigate } = action.payload;
        storage.delete(StorageKey.DATA_ROOM_TOKEN);
        yield put(AuthActions.dataRoomLogoutSuccess(navigate));
    } catch (e) {
        yield put(AuthActions.dataRoomLogoutFailed());
    }
}

function dataRoomUserLogoutSuccess(action: ReturnType<typeof AuthActions.dataRoomLogoutSuccess>) {
    const { navigate } = action.payload;
    void navigate('/dataroom/login');
}

function* authenticateDataRoomUser() {
    try {
        const token = storage.get(StorageKey.DATA_ROOM_TOKEN);

        if (token) {
            const user: Awaited<ReturnType<typeof authService.authenticatedDataRoomUser>> = yield call(authService.authenticatedDataRoomUser);
            yield put(AuthActions.authenticateDataRoomUserSuccess(user));
        } else {
            yield put(AuthActions.authenticateDataRoomUserFailed());
        }
    } catch (e) {
        storage.delete(StorageKey.DATA_ROOM_TOKEN);
        yield put(AuthActions.authenticateDataRoomUserFailed());
    }
}

export function* authSaga() {
    yield all([
        takeLatest(BORROWER_LOGIN, borrowerLogin),
        takeLatest(BORROWER_LOGOUT, borrowerLogout),
        takeLatest(BORROWER_LOGOUT_SUCCESS, borrowerLogoutSuccess),
        takeLatest(AUTHENTICATE_BORROWER, authenticateBorrower),
        takeLatest(DATA_ROOM_LOGIN, dataRoomUserLogin),
        takeLatest(DATA_ROOM_LOGOUT, dataRoomUserLogout),
        takeLatest(DATA_ROOM_LOGOUT_SUCCESS, dataRoomUserLogoutSuccess),
        takeLatest(AUTHENTICATE_DATA_ROOM_USER, authenticateDataRoomUser),
    ]);
}
